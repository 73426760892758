<template>
  <v-container v-if="!_.isEmpty(partnerEditable)">
    <!-- Banner header -->
    <Banner :title="'Partner'">
      <template v-slot:actions>
        <v-btn v-if="permissions.downloadReport.includes(user.role)" small @click="downloadRepaymentsReport()">
          <v-img :src="require(`@/assets/icons/download_pdf.png`)" max-width="24" class="mr-2" />
          Reporte de cobros
        </v-btn>
      </template>
    </Banner>

    <!-- Form profile data -->
    <v-card class="my-8 pa-5">
      <v-card-text class="pa-5">
        <v-form ref="formEditPartner" v-model="formEditPartnerValid">
          <v-text-field v-model="partnerEditable.name" label="Nombre" :rules="formRules.textRules" :readonly="!editable" />
          <v-text-field
            v-model.number="partnerEditable.withholding_withdraws"
            type="number"
            label="Retenciones"
            suffix="%"
            :rules="formRules.numberRulesAllowZero"
            :readonly="!editable"
          />
          <CountryAutocomplete
            :listener="partner"
            :countryId="partnerEditable.country"
            :editable="editable"
            @selected="partnerEditable.country = $event"
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-icon v-if="!editable && permissions.profileData.edit.includes(user.role)" @click="editable = true">edit</v-icon>
        <v-btn v-if="editable" text @click="(editable = false), formatFields()">Cancelar</v-btn>
        <v-btn v-if="editable" text @click="edit">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import listeners from "@/firebase/listeners";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import CommonMixin from "@/mixins/CommonMixin";
import _ from "lodash";
import Banner from "@/components/elements/Banner";
import CountryAutocomplete from "@/components/elements/locations/CountryAutocomplete.vue";

export default {
  name: "Partner",
  components: {
    Banner,
    CountryAutocomplete
  },
  mixins: [CommonMixin, FormRulesMixin, FormatDateMixin],
  data() {
    return {
      // Cards actions permissions
      permissions: {
        profileData: {
          edit: ["SUPERADMIN", "ADMIN"]
        },
        downloadReport: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"]
      },

      // Form edit partner
      editable: false,
      formEditPartnerValid: false,
      partnerEditable: {}
    };
  },
  computed: {
    ...mapState({
      partner: (state) => state.partners.partner,
      user: (state) => state.user.user
    }),
    _() {
      return _;
    }
  },
  watch: {
    partner() {
      this.formatFields();
    },
    editable() {
      this.$store.commit("SET_EDITING", this.editable);
    }
  },
  async created() {
    this.$store.commit("SET_LOADING", true);
    await listeners.partner(this.$route.params.id);
    // Push back if id does not exist
    if (this._.isEmpty(this.partner) || this.partner.id !== this.$route.params.id) {
      this.$router.push("/partners");
      this.$store.commit("SET_LOADING", false);
      return;
    }
    this.formatFields();
    this.$store.commit("SET_LOADING", false);
  },
  methods: {
    // Format fields editables
    formatFields() {
      const partnerFromListener = this.partner;
      this.partnerEditable = this._.cloneDeep(partnerFromListener);
    },

    // Edit partner
    async edit() {
      if (this.$refs.formEditPartner.validate()) {
        let partnerEditableFormattedData = {
          id: this.partnerEditable.id,
          name: this.partnerEditable.name,
          withholding_withdraws: this.partnerEditable.withholding_withdraws
        };

        this.$store.dispatch("partners/editPartner", partnerEditableFormattedData);

        this.formatFields();
        this.editable = false;
      }
    },

    async downloadRepaymentsReport() {
      const { data: path } = await this.$store.dispatch("partners/generateRepaymentsReport", this.partnerEditable.id);
      window.open(path, "_blank");
    }
  }
};
</script>
